import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { IconButton } from '@mui/material';
import { 
  Close as CloseIcon, 
  Share as ShareIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon 
} from '@mui/icons-material';
import styles from './StorySlideshow.module.css';

type Direction = 'left' | 'right';

interface Slide {
  id: string;
  title: string;
  background: string;
  content: React.ReactNode;
}

interface StorySlideshowProps {
  slides: Slide[];
  onClose: () => void;
  onShare: () => void;
}

const slideVariants = {
  enter: (direction: Direction) => ({
    x: direction === 'right' ? 100 : -100,
    opacity: 0
  }),
  center: {
    x: 0,
    opacity: 1
  },
  exit: (direction: Direction) => ({
    x: direction === 'right' ? -100 : 100,
    opacity: 0
  })
};

export const StorySlideshow = ({ slides, onClose, onShare }: StorySlideshowProps) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [direction, setDirection] = useState<Direction>('right');
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);

  const minSwipeDistance = 50;

  const onTouchStart = (e: React.TouchEvent) => {
    setTouchEnd(null);
    if (e.targetTouches?.[0]) {
      setTouchStart(e.targetTouches[0].clientX);
    }
  };

  const onTouchMove = (e: React.TouchEvent) => {
    if (e.targetTouches?.[0]) {
      setTouchEnd(e.targetTouches[0].clientX);
    }
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isLeftSwipe && currentSlide < slides.length - 1) {
      handleNextSlide();
    }
    if (isRightSwipe && currentSlide > 0) {
      handlePrevSlide();
    }
  };

  const handlePrevSlide = () => {
    if (currentSlide > 0) {
      setDirection('left');
      setCurrentSlide(prev => prev - 1);
    }
  };

  const handleNextSlide = () => {
    if (currentSlide < slides.length - 1) {
      setDirection('right');
      setCurrentSlide(prev => prev + 1);
    }
  };

  const currentSlideData = slides[currentSlide];

  if (!currentSlideData) {
    return null; // or some error state
  }

  return (
    <div className={styles.slideshowContainer}>
      <div className={styles.controls}>
        <IconButton 
          className={styles.controlButton} 
          onClick={onClose}
          aria-label="Close slideshow"
        >
          <CloseIcon />
        </IconButton>
        <IconButton 
          className={styles.controlButton} 
          onClick={onShare}
          aria-label="Share"
        >
          <ShareIcon />
        </IconButton>
      </div>

      <div className={styles.progressBar}>
        {slides.map((_, index) => (
          <div
            key={index}
            className={`${styles.progressDot} ${
              index === currentSlide ? styles.active : ''
            }`}
          />
        ))}
      </div>

      <div className={styles.navigationControls}>
        <IconButton 
          className={`${styles.navButton} ${currentSlide === 0 ? styles.hidden : ''}`}
          onClick={handlePrevSlide}
          aria-label="Previous slide"
          disabled={currentSlide === 0}
        >
          <ChevronLeftIcon />
        </IconButton>
        
        <IconButton 
          className={`${styles.navButton} ${
            currentSlide === slides.length - 1 ? styles.hidden : ''
          }`}
          onClick={handleNextSlide}
          aria-label="Next slide"
          disabled={currentSlide === slides.length - 1}
        >
          <ChevronRightIcon />
        </IconButton>
      </div>

      <AnimatePresence mode="wait" custom={direction}>
        <motion.div
          key={currentSlide}
          className={styles.slide}
          style={{ background: currentSlideData.background }}
          custom={direction}
          variants={slideVariants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ duration: 0.3 }}
          onClick={handleNextSlide}
        >
          <h2 className={styles.slideTitle}>{currentSlideData.title}</h2>
          <div className={styles.slideContent}>
            {currentSlideData.content}
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
