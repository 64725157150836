import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Text from "../../../components/Text";
import { selectUser } from "../../../services/redux/modules/user/selector";
import { getSpotifyLogUrl } from "../../../services/tools";
import { FaMusic, FaChartBar, FaBook, FaQuestion, FaEnvelope } from 'react-icons/fa';
import s from "../index.module.css";

export default function Login() {
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [navigate, user]);

  const handleLogin = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const spotifyLoginUrl = `${getSpotifyLogUrl()}?timezone=${encodeURIComponent(timezone)}`;
    window.location.href = spotifyLoginUrl;
  };

  return (
    <div className={`${s.root}`}>
      <div className={`${s.animated_gradient_wave}`}>
        <div className={`${s.wave_container}`}>
          <div className={`${s.wave}`}></div>
        </div>
      </div>
      <div className={`${s.content}`}>
        <Text element="h1" className={`${s.title}`}>
          Welcome to <span className={`${s.highlight}`}>My Day InMusic</span>
        </Text>
        {user ? (
          <div className={`${s.loggedIn}`}>
            <Text className={`${s.welcome}`}>
              You're already logged in. Ready to explore your musical journey?
            </Text>
            <button className={`${s.ctaButton}`} onClick={() => navigate("/dashboard")}>
              View Your Dashboard
            </button>
          </div>
        ) : (
          <>
            <Text className={`${s.subtitle}`}>
              Uncover the soundtrack of your life with AI-powered music insights
            </Text>
            <div className={`${s.featuresContainer}`}>
              <div className={`${s.features}`}>
                <Text element="h2">Discover Your Music:</Text>
                <ul>
                  <li><FaMusic /> <span>Reveal hidden meanings in your favorite songs</span></li>
                  <li><FaChartBar /> <span>Analyze your mood through your music choices</span></li>
                  <li><FaBook /> <span>Get personalized song and book recommendations</span></li>
                  <li><FaQuestion /> <span>Ask questions about your listening habits</span></li>
                  <li><FaEnvelope /> <span>Receive daily summaries of your musical journey</span></li>
                </ul>
              </div>
              <div className={`${s.ctaContainer}`}>
                <button className={`${s.ctaButton}`} onClick={handleLogin}>
                  Connect with Spotify
                </button>
                <Text className={`${s.info}`}>
                  Start your musical adventure today!
                </Text>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
