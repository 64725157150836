import React, { useState } from "react";
import { Typography, Paper, IconButton, TextField, Button, Alert } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { api } from "../../../services/apis/api";
import s from "./index.module.css";

export default function NotRegistered() {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState<'idle' | 'submitting' | 'success' | 'error'>('idle');
  const [error, setError] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setStatus('submitting');
    
    try {
      await api.submitUnregisteredEmail(email);
      setStatus('success');
    } catch (err) {
      setStatus('error');
      setError(err.message || 'Failed to submit email');
    }
  };

  return (
    <div className={s.root}>
      <div className={s.content}>
        <Paper elevation={3} className={s.paper}>
          <div className={s.iconContainer}>
            <IconButton className={s.infoButton}>
              <InfoOutlined className={s.icon} />
            </IconButton>
          </div>
          <Typography variant="h4" gutterBottom>
            Registration Pending
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Your account is not yet registered
          </Typography>
          <Typography variant="body1" paragraph>
            Thank you for your interest in MyDayInMusic! Our application is currently in development mode, and we need to manually add your email to our Spotify Developer Dashboard.
          </Typography>
          
          {status === 'success' ? (
            <Alert severity="success" sx={{ mt: 2 }}>
              Thank you! We'll process your registration request and contact you soon.
            </Alert>
          ) : (
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                label="Email Address"
                required
                sx={{ mb: 2 }}
              />
              <Button 
                type="submit" 
                variant="contained" 
                disabled={status === 'submitting'}
              >
                {status === 'submitting' ? 'Submitting...' : 'Submit'}
              </Button>
              {status === 'error' && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {error}
                </Alert>
              )}
            </form>
          )}
        </Paper>
      </div>
    </div>
  );
}


