import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import Header from "../../components/Header";
import History from "../../components/History";
import ArtistsListened from "../../components/ImplementedCards/ArtistsListened";
import BestArtist from "../../components/ImplementedCards/BestArtist";
import BestSong from "../../components/ImplementedCards/BestSong";
import SongsListened from "../../components/ImplementedCards/SongsListened";
import TimeListened from "../../components/ImplementedCards/TimeListened";
import ListeningRepartition from "../../components/ImplementedCharts/ListeningRepartition";
import TimeListenedPer from "../../components/ImplementedCharts/TimeListenedPer";
import { selectUser } from "../../services/redux/modules/user/selector";
import s from "./index.module.css";
import { Button } from "@mui/material";
import { api } from "../../services/apis/api";
import { useAppDispatch } from "../../services/redux/tools"; // Changed from store to tools
import { alertMessage } from "../../services/redux/modules/message/reducer";

export default function Home() {
  const user = useSelector(selectUser);

  if (!user) {
    return null;
  }

  const dispatch = useAppDispatch();

  const handleTestEmail = async (type: 'yesterday' | 'today') => {
    try {
      const { data } = await api.testDailyEmail(type);
      dispatch(
        alertMessage({
          level: "success",
          message: data.message,
        })
      );
    } catch (error) {
      dispatch(
        alertMessage({
          level: "error",
          message: `Failed to send test ${type}'s summary`,
        })
      );
    }
  };

  return (
    <div>
      <Header
        title={`Welcome, ${user.username} 🎉`}
        tinyTitle="Welcome"
        subtitle="Here is what happened for the period you chose on the right"
      />
      <div className={s.content}>
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={12} md={12} lg={4}>
            <SongsListened />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TimeListened />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ArtistsListened />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <TimeListenedPer className={s.timelisten} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <BestArtist />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <ListeningRepartition className={s.timelisten} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <BestSong />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <History />
          </Grid>
        </Grid>
      </div>
      <Button variant="contained" onClick={() => handleTestEmail('yesterday')}>
        Test Yesterday's Summary
      </Button>
      <Button variant="contained" onClick={() => handleTestEmail('today')}>
        Test Today's Summary
      </Button>
    </div>
  );
}