import { Dialog, DialogTitle, DialogContent, IconButton, CircularProgress } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { SongMeaningResponse } from '../services/apis/api';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  analysis: SongMeaningResponse | null;
  isLoading: boolean;
}

export const SongMeaningPopup = ({ isOpen, onClose, analysis, isLoading }: Props) => {
  return (
    <Dialog 
      open={isOpen} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Song Analysis
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', padding: '3rem' }}>
            <CircularProgress />
          </div>
        ) : analysis?.analyzed_songs[0] ? (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <div>
              <h3 style={{ color: '#4caf50', marginBottom: '0.5rem' }}>Theme</h3>
              <p>{analysis.analyzed_songs[0].analysis.theme}</p>
            </div>
            
            <div>
              <h3 style={{ color: '#4caf50', marginBottom: '0.5rem' }}>Interpretation</h3>
              <p>{analysis.analyzed_songs[0].analysis.interpretation}</p>
            </div>

            <div>
              <h3 style={{ color: '#4caf50', marginBottom: '0.5rem' }}>Cultural Context</h3>
              <p>{analysis.analyzed_songs[0].analysis.cultural_context}</p>
            </div>

            <div>
              <h3 style={{ color: '#4caf50', marginBottom: '0.5rem' }}>Musical Elements</h3>
              <ul style={{ paddingLeft: '1.5rem' }}>
                {analysis.analyzed_songs[0].analysis.musical_elements.map((element, i) => (
                  <li key={i}>{element}</li>
                ))}
              </ul>
            </div>

            <div>
              <h3 style={{ color: '#4caf50', marginBottom: '0.5rem' }}>Related Works</h3>
              <ul style={{ paddingLeft: '1.5rem' }}>
                {analysis.analyzed_songs[0].analysis.related_works.map((work, i) => (
                  <li key={i}>
                    {work.title} by {work.artist} - {work.connection}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ) : (
          <div style={{ textAlign: 'center', padding: '2rem' }}>
            No analysis available for this song.
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
