import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import s from './index.module.css';

export default function PaymentSuccess() {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to stats page after 3 seconds
    const timer = setTimeout(() => {
      navigate('/all');
    }, 3000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className={s.root}>
      <div className={s.content}>
        <h1>Payment Successful!</h1>
        <p>Thank you for upgrading to Premium.</p>
        <p>You will be redirected to your stats shortly...</p>
        <CircularProgress className={s.spinner} />
      </div>
    </div>
  );
}
