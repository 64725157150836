import { Fragment, useMemo } from "react";
import clsx from "clsx";
import { msToMinutesAndSeconds } from "../../../../services/stats";
import { Artist, Album, Track as TrackType } from "../../../../services/types";
import InlineArtist from "../../../../components/InlineArtist";
import InlineTrack from "../../../../components/InlineTrack";
import Text from "../../../../components/Text";
import PlayButton from "../../../../components/PlayButton";
import TrackOptions from "../../../../components/TrackOptions";
import { useMobile } from "../../../../services/hooks/hooks";
import { ColumnDescription, GridRowWrapper } from "../../../../components/Grid";
import InlineAlbum from "../../../../components/InlineAlbum";
import LongClickableTrack from "../../../../components/LongClickableTrack";
import s from "./index.module.css";
import { useTrackGrid } from "./TrackGrid";

export interface TrackProps {
  track: TrackType;
  album: Album;
  artists: Artist[];
  playable?: boolean;
  duration?: number;
  totalDuration?: number;
}

export default function Track({
  track,
  album,
  artists,
  playable,
  duration = track.duration_ms,
  totalDuration = 0,
}: TrackProps) {
  const [isMobile, isTablet] = useMobile();
  const trackGrid = useTrackGrid();

  const columns = useMemo<ColumnDescription[]>(
    () => [
      {
        ...trackGrid.cover,
        node: playable && <PlayButton id={track.id} covers={album.images} />,
      },
      {
        ...trackGrid.title,
        node: (
          <div className={clsx("otext", s.names)}>
            <InlineTrack element="div" track={track} />
            <div className="subtitle">
              {artists.map((art, k, a) => (
                <Fragment key={art.id}>
                  <InlineArtist artist={art} noStyle />
                  {k !== a.length - 1 && ", "}
                </Fragment>
              ))}
            </div>
          </div>
        ),
      },
      {
        ...trackGrid.album,
        node: !isTablet && (
          <InlineAlbum element="div" className="otext" album={album} />
        ),
      },
      {
        ...trackGrid.duration,
        node: !isMobile && (
          <Text element="div">{msToMinutesAndSeconds(track.duration_ms)}</Text>
        ),
      },
    ],
    [album, artists, isMobile, isTablet, playable, track],
  );

  return (
    <LongClickableTrack track={track}>
      <GridRowWrapper
        columns={columns}
        className={clsx("play-button-holder", s.row)}
      />
    </LongClickableTrack>
  );
}
