import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { CheckCircle } from '@mui/icons-material';
import s from './index.module.css';

interface PaymentPopupProps {
  open: boolean;
  onClose: () => void;
  onSelectPlan: (planType: 'monthly' | 'yearly' | 'lifetime') => void;
}

const PLANS = [
  {
    type: 'monthly' as const,
    title: 'Monthly',
    price: '$4.99',
    period: 'month',
    subtitle: 'Commitment issues? We get it.',
    features: [
      '7-day free trial',
      'All premium features',
      'Unlimited statistics',
      'Priority support',
      'Cancel anytime'
    ]
  },
  {
    type: 'yearly' as const,
    title: 'Yearly',
    price: '$39.99',
    period: 'year',
    subtitle: 'Smart choice! Save 35%!',
    features: [
      '7-day free trial',
      'All premium features',
      'Unlimited statistics',
      'Priority support',
      'Save 17% vs monthly'
    ],
    recommended: true
  },
  {
    type: 'lifetime' as const,
    title: 'Lifetime',
    price: '$49.99',
    period: 'forever',
    subtitle: 'Basically stealing from us',
    features: [
      'All premium features',
      'Unlimited statistics',
      'Priority support',
      'Save 90% long-term'
    ]
  }
];

export default function PaymentPopup({ open, onClose, onSelectPlan }: PaymentPopupProps) {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
      classes={{ paper: s.dialog }}
    >
      <DialogTitle className={s.title}>
        Upgrade to Premium
        <IconButton onClick={onClose} className={s.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={s.content}>
        <div className={s.header}>
          <h2>Unlock Advanced Statistics</h2>
          <p>Get detailed insights into your music listening habits</p>
        </div>
        
        <div className={s.plans}>
          {PLANS.map((plan) => (
            <div 
              key={plan.type}
              className={`${s.plan} ${plan.recommended ? s.recommended : ''}`}
            >
              {plan.recommended && (
                <div className={s.recommendedBadge}>
                  MOST POPULAR
                </div>
              )}
              <h3>{plan.title}</h3>
              <p className={s.subtitle}>{plan.subtitle}</p>
              <div className={s.price}>
                <span className={s.amount}>{plan.price}</span>
                <span className={s.period}>/{plan.period}</span>
              </div>
              <ul className={s.features}>
                {plan.features.map((feature, index) => (
                  <li key={index}>
                    <CheckCircle className={s.checkIcon} />
                    {feature}
                  </li>
                ))}
              </ul>
              <button 
                className={s.selectButton}
                onClick={() => onSelectPlan(plan.type)}
              >
                Select Plan
              </button>
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
}
