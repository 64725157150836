import { loadStripe as loadStripeJs } from '@stripe/stripe-js';

let stripePromise: Promise<any> | null = null;

export const loadStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripeJs('pk_test_51QB2A9GIfZoa12CgGad1nOpJi1CJD8tWGYpspCoEN7TCFhDPY4E1s79omd31AY2crXsq2ZyU4zBjzFBN3tdDtS4m003vXQ1Mx0');
  }
  return stripePromise;
};
