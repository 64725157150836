import {
  getPresetIndexFromIntervalDetail,
  getUserBasedIndexFromIntervalDetail,
  IntervalDetail,
  presetIntervals,
  userBasedIntervals,
  defaultInterval,
  lastDay,
  now,
} from "../../../intervals";
import { Timesplit } from "../../../types";
import { ReduxIntervalDetail } from "./types";

export function intervalDetailToRedux(
  intervalDetail: IntervalDetail,
): ReduxIntervalDetail {
  switch (intervalDetail.type) {
    case "preset": {
      return {
        type: "preset",
        index: getPresetIndexFromIntervalDetail(intervalDetail),
      };
    }
    case "userbased": {
      return {
        type: "userbased",
        index: getUserBasedIndexFromIntervalDetail(intervalDetail),
      };
    }
    case "custom": {
      return {
        type: "custom",
        interval: intervalDetail.interval,
      };
    }
    default: {
      return {
        type: "custom",
        interval: {
          timesplit: Timesplit.day,
          start: lastDay,
          end: now,
        },
      };
    }
  }
}

export function fromReduxIntervalDetail(
  intervalDetail: ReduxIntervalDetail,
): IntervalDetail {
  switch (intervalDetail.type) {
    case "preset": {
      return presetIntervals[intervalDetail.index]!;
    }
    case "userbased": {
      return userBasedIntervals[intervalDetail.index]!;
    }
    case "custom": {
      return {
        type: "custom",
        name: "custom",
        interval: intervalDetail.interval,
      };
    }
    default: {
      return presetIntervals[0];
    }
  }
}

export function getDefaultIntervalDetail(): IntervalDetail {
  try {
    // Use the first preset (All time Spotify) as default
    return presetIntervals[0];
  } catch (e) {
    // Fallback to a valid PresetIntervalDetail
    return {
      type: "preset",
      name: "Last 24 hours",
      unit: "day",
      interval: {
        timesplit: Timesplit.day,
        start: lastDay,
        end: now,
      },
      isCalculated: true,
    };
  }
}
