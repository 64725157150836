import { MenuItem } from "@mui/material";
import { useCallback, useState } from "react";
import { useMobile } from "../../services/hooks/hooks";
import { setPlaylistContext } from "../../services/redux/modules/playlist/reducer";
import { playTrack } from "../../services/redux/modules/user/thunk";
import { useAppDispatch } from "../../services/redux/tools";
import { Track } from "../../services/types";
import { SongMeaningPopup } from "../SongMeaningPopup";
import { api } from "../../services/apis/api";
import { SongMeaningResponse } from "../../services/apis/api";
import s from "./index.module.css";

interface TrackOptionsContentProps {
  onClose: () => void;
  track: Track;
}

export default function TrackOptionsContent({
  onClose,
  track,
}: TrackOptionsContentProps) {
  const dispatch = useAppDispatch();
  const [isMobile] = useMobile();
  const [isAnalysisOpen, setIsAnalysisOpen] = useState(false);
  const [analysis, setAnalysis] = useState<SongMeaningResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const add = useCallback(() => {
    onClose();
    dispatch(
      setPlaylistContext({
        type: "single",
        songId: track.id,
      }),
    );
  }, [dispatch, onClose, track.id]);

  const play = useCallback(() => {
    dispatch(playTrack(track.id));
  }, [dispatch, track.id]);

  const analyze = useCallback(async () => {
    setIsAnalysisOpen(true);
    setIsLoading(true);
    try {
      const { data } = await api.getSongMeaning(track.id);
      setAnalysis(data);
    } catch (error) {
      console.error('Error analyzing song:', error);
    } finally {
      setIsLoading(false);
    }
  }, [track.id]);

  return (
    <>
      {isMobile && (
        <MenuItem className={s.item} onClick={play}>
          Play
        </MenuItem>
      )}
      <MenuItem className={s.item} onClick={add}>
        Add to playlist
      </MenuItem>
      <MenuItem className={s.item} onClick={analyze}>
        Analyze meaning
      </MenuItem>
      <SongMeaningPopup
        isOpen={isAnalysisOpen}
        onClose={() => setIsAnalysisOpen(false)}
        analysis={analysis}
        isLoading={isLoading}
      />
    </>
  );
}
