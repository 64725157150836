import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import ArtistListeningRepartition from "../../components/ImplementedCharts/ArtistListeningRepartition";
import AverageAlbumReleaseDate from "../../components/ImplementedCharts/AverageAlbumReleaseDate";
import AverageNumberArtistPer from "../../components/ImplementedCharts/AverageNumberArtistPer";
import AverageSongPopularityPer from "../../components/ImplementedCharts/AverageSongPopularityPer";
import BestArtistsBar from "../../components/ImplementedCharts/BestArtistsBar";
import BestOfHour from "../../components/ImplementedCharts/BestOfHour";
import DifferentArtistListenedPer from "../../components/ImplementedCharts/DifferentArtistListenedPer";
import ListeningRepartition from "../../components/ImplementedCharts/ListeningRepartition";
import SongsListenedPer from "../../components/ImplementedCharts/SongsListenedPer";
import TimeListenedPer from "../../components/ImplementedCharts/TimeListenedPer";
import { selectUser } from "../../services/redux/modules/user/selector";
import PaymentPopup from "../../components/PaymentPopup/PaymentPopup";
import { api } from "../../services/apis/api";
import { loadStripe } from "../../services/stripe";
import s from "./index.module.css";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setUser } from "../../services/redux/modules/user/reducer";

export default function AllStats() {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // Check URL parameters for payment status
    const urlParams = new URLSearchParams(window.location.search);
    const paymentStatus = urlParams.get('payment_status');
    
    if (paymentStatus === 'success') {
      // Clear the URL parameters
      window.history.replaceState({}, '', window.location.pathname);
      
      // Refresh user data to get updated subscription status
      api.me().then(({ data }) => {
        if (data.status) {
          dispatch(setUser(data.user));
        }
      });
    }
  }, [dispatch]);

  if (!user) {
    return null;
  }

  const hasAccess = user.subscription?.type === 'premium' || user.subscription?.type === 'unlimited';
  const isSubscriptionExpired = user.subscription?.type === 'premium' && 
    new Date() > new Date(user.subscription.expiresAt);
  const needsPaymentAction = user.subscription?.type === 'premium' && 
    user.subscription.status === 'action_required';

  if (!hasAccess || isSubscriptionExpired || needsPaymentAction) {
    return (
      <PaymentPopup
        open={true}
        onClose={() => navigate('/')}
        onSelectPlan={async (planType) => {
          try {
            const { sessionId } = await api.createCheckoutSession(planType);
            const stripe = await loadStripe();
            if (!stripe) {
              throw new Error('Stripe failed to load');
            }
            const result = await stripe.redirectToCheckout({ sessionId });
            if (result.error) {
              throw new Error(result.error.message);
            }
          } catch (error) {
            console.error('Error creating checkout session:', error);
          }
        }}
      />
    );
  }

  return (
    <div className={s.root}>
      <Header
        title="All stats"
        subtitle="You can find here all kind of stats based on the time span on the right"
      />
      <div className={s.content}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={6}>
            <BestArtistsBar className={s.chart} />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <ListeningRepartition className={s.chart} />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <ArtistListeningRepartition className={s.chart} />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <BestOfHour className={s.chart} />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <SongsListenedPer className={s.chart} />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <TimeListenedPer className={s.chart} />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <DifferentArtistListenedPer className={s.chart} />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <AverageAlbumReleaseDate className={s.chart} />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <AverageNumberArtistPer className={s.chart} />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <AverageSongPopularityPer className={s.chart} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
