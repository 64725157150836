import { IconButton } from "@mui/material";
import { Menu } from "@mui/icons-material";
import React, { useCallback, useContext, ReactNode } from "react";
import { useSelector } from "react-redux";
import { IntervalDetail, SpotifyTimeRange } from "../../services/intervals";
import { setDataInterval, setSpotifyTimeRange } from "../../services/redux/modules/user/reducer";
import { selectIntervalDetail } from "../../services/redux/modules/user/selector";
import { selectSpotifyTimeRange } from "../../services/redux/modules/user/selector";
import { intervalDetailToRedux } from "../../services/redux/modules/user/utils";
import { useAppDispatch } from "../../services/redux/tools";
import { IntervalSelector } from "../IntervalSelector";
import { SpotifyTimeRangeSelector } from "../SpotifyTimeRangeSelector";
import Text from "../Text";
import { LayoutContext } from "../Layout/LayoutContext";
import { useSider } from "../Layout/useSider";
import s from "./index.module.css";

type TimeSelectorType = 'none' | 'spotify' | 'time';

interface HeaderProps {
  left?: React.ReactNode;
  right?: React.ReactNode;
  title: React.ReactNode;
  tinyTitle?: string;
  subtitle: ReactNode;
  timeSelectorType?: TimeSelectorType;
}

export default function Header({
  left,
  right,
  title,
  tinyTitle,
  subtitle,
  timeSelectorType = 'time',
}: HeaderProps) {
  const dispatch = useAppDispatch();
  const intervalDetail = useSelector(selectIntervalDetail);
  const spotifyTimeRange = useSelector(selectSpotifyTimeRange);
  const layoutContext = useContext(LayoutContext);
  const { siderAllowed, siderIsDrawer } = useSider();

  const changeInterval = useCallback(
    (newInterval: IntervalDetail) => {
      dispatch(setDataInterval(intervalDetailToRedux(newInterval)));
    },
    [dispatch],
  );

  const changeSpotifyRange = useCallback(
    (newRange: SpotifyTimeRange) => {
      dispatch(setSpotifyTimeRange(newRange));
    },
    [dispatch],
  );

  return (
    <div className={s.root}>
      <div className={s.left}>
        {siderAllowed && siderIsDrawer && (
          <IconButton
            onClick={layoutContext.openDrawer}
            className={s.drawerbutton}>
            <Menu />
          </IconButton>
        )}
        {left}
        <div className={s.texts}>
          <Text element="h1">
            {siderIsDrawer && tinyTitle ? tinyTitle : title}
          </Text>
          {!siderIsDrawer && <Text>{subtitle}</Text>}
        </div>
      </div>
      {right}
      {timeSelectorType !== 'none' && (
        <div>
          {timeSelectorType === 'time' ? (
            <IntervalSelector 
              value={intervalDetail} 
              onChange={changeInterval} 
            />
          ) : (
            <SpotifyTimeRangeSelector 
              value={spotifyTimeRange} 
              onChange={changeSpotifyRange}
            />
          )}
        </div>
      )}
    </div>
  );
}