import React, { useState, useEffect } from "react";
import { api } from "../../services/apis/api";
import Header from "../../components/Header";
import TitleCard from "../../components/TitleCard";
import Loader from "../../components/Loader";
import Text from "../../components/Text";
import { Grid } from "@mui/material";
import { SpotifyTimeRange } from "../../services/intervals";
import { SpotifyTimeRangeSelector } from "../../components/SpotifyTimeRangeSelector/SpotifyTimeRangeSelector";
import styles from "./TrackRoast.module.css";
import { RoastLevel } from "../../services/apis/api";
import { Slider, Box, Typography } from "@mui/material";
import { LocalFireDepartment } from "@mui/icons-material";
import { Button, IconButton, Stack, Tooltip } from "@mui/material";
import { 
  Share as ShareIcon,
  Twitter as TwitterIcon,
  Facebook as FacebookIcon,
  Reddit as RedditIcon,
} from "@mui/icons-material";
import { RoastSlideshow } from "./RoastSlideshow";
import { RoastResponse } from "../../services/apis/api";
import { motion } from "framer-motion";
import { StorySlideshow } from "../../components/StorySlideshow/StorySlideshow";

const RoastLevelSelector = ({ value, onChange }: { 
  value: RoastLevel, 
  onChange: (value: RoastLevel) => void 
}) => {
  const [localValue, setLocalValue] = useState(value);
  const levels: RoastLevel[] = ['light', 'medium', 'dark', 'burned'];
  const levelIndex = levels.indexOf(localValue);
  
  const getColor = (value: number, isDarkMode: boolean) => {
    const lightColors = {
      light: '#91A676',
      medium: '#A67B5B', 
      dark: '#8B4513',
      burned: '#2F1810'
    };
    
    const darkColors = {
      light: '#91A676',
      medium: '#8B4513',
      dark: '#654321',
      burned: '#2F1810'
    };

    const colors = isDarkMode ? darkColors : lightColors;
    
    if (value <= 25) return colors.light;
    if (value <= 50) return colors.medium;
    if (value <= 75) return colors.dark;
    return colors.burned;
  };

  return (
    <Box sx={{ width: 300, textAlign: 'center' }}>
      <LocalFireDepartment 
        sx={{ 
          fontSize: 40, 
          color: getColor(levelIndex * 33.33, false),
          mb: 1
        }} 
      />
      <Slider
        value={levelIndex * 33.33}
        onChange={(_, newValue) => {
          const index = Math.round((newValue as number) / 33.33);
          const selectedLevel = levels[index];
          if (index >= 0 && index < levels.length && selectedLevel) {
            setLocalValue(selectedLevel);
          }
        }}
        onChangeCommitted={(_, newValue) => {
          const index = Math.round((newValue as number) / 33.33);
          const selectedLevel = levels[index];
          if (index >= 0 && index < levels.length && selectedLevel) {
            onChange(selectedLevel);
          }
        }}
        sx={{
          '& .MuiSlider-thumb': {
            color: getColor(levelIndex * 33.33, false),
          },
          '& .MuiSlider-track': {
            background: `linear-gradient(to right, #91A676, #8B4513, #654321, #2F1810)`,
            border: 'none',
          },
          '& .MuiSlider-rail': {
            background: `linear-gradient(to right, #91A676, #8B4513, #654321, #2F1810)`,
            opacity: 0.3,
          }
        }}
        step={33.33}
        marks={[
          { value: 0, label: 'Light Roast' },
          { value: 33.33, label: 'Medium Roast' },
          { value: 66.66, label: 'Dark Roast' },
          { value: 100, label: 'French Roast' }
        ]}
      />
    </Box>
  );
};

const canShare = () => {
  return typeof navigator !== 'undefined' && navigator.share;
};

const handleShare = async (caption: string) => {
  const shareData = {
    title: 'My Spotify Roast | MyDayInMusic',
    text: `${caption}\n\n🎵 spotify.mydayinmusic.com/roast`,
    url: 'https://spotify.mydayinmusic.com/roast'
  };

  if (canShare()) {
    try {
      await navigator.share(shareData);
    } catch (err) {
      console.log('Error sharing:', err);
    }
  }
};

const shareToTwitter = (caption: string) => {
  const text = encodeURIComponent(`${caption}\n\n🎵 spotify.mydayinmusic.com/roast`);
  const url = encodeURIComponent('https://spotify.mydayinmusic.com/roast');
  const hashtags = 'MyDayInMusic';
  window.open(`https://twitter.com/intent/tweet?text=${text}&url=${url}&hashtags=${hashtags}`, '_blank');
};

const shareToFacebook = () => {
  const url = encodeURIComponent('https://spotify.mydayinmusic.com/roast');
  window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, '_blank');
};

const shareToReddit = (caption: string) => {
  const title = encodeURIComponent(`${caption}`);
  const url = encodeURIComponent('https://spotify.mydayinmusic.com/roast');
  window.open(`https://reddit.com/submit?title=${title}&url=${url}`, '_blank');
};

const createRoastSlides = (roastData: RoastResponse['roast']) => [
  {
    id: 'overall',
    title: 'Your Music Taste...',
    background: 'linear-gradient(45deg, #FF6B6B, #4ECDC4)',
    content: (
      <div className={styles.overallRoast}>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          style={{ color: '#E0E0E0' }}
        >
          {roastData.overall_roast}
        </motion.p>
      </div>
    )
  },
  {
    id: 'specifics',
    title: 'Let\'s Break It Down',
    background: 'linear-gradient(45deg, #6C5CE7, #A8E6CF)',
    content: (
      <div className={styles.specificsContainer}>
        {roastData.specific_roasts.map((roast, index) => (
          <motion.div
            key={index}
            className={styles.roastItem}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.2 }}
          >
            <h3>{roast.target}</h3>
            <p>{roast.roast}</p>
          </motion.div>
        ))}
      </div>
    )
  },
  {
    id: 'memes',
    title: 'The Meme-worthy Moments',
    background: 'linear-gradient(45deg, #FF8B94, #4A90E2)',
    content: (
      <div className={styles.memesContainer}>
        {roastData.meme_worthy_moments.map((moment, index) => (
          <motion.div
            key={index}
            className={styles.memeItem}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: index * 0.2 }}
          >
            <p>{moment}</p>
          </motion.div>
        ))}
      </div>
    )
  },
  {
    id: 'redeeming',
    title: 'Silver Linings',
    background: 'linear-gradient(45deg, #98DDCA, #6C5CE7)',
    content: (
      <div className={styles.redeemingContainer}>
        {roastData.redeeming_qualities.map((quality, index) => (
          <motion.div
            key={index}
            className={styles.qualityItem}
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: index * 0.2 }}
          >
            <p>{quality}</p>
          </motion.div>
        ))}
      </div>
    )
  },
  {
    id: 'next-steps',
    title: 'Want to see more?',
    background: 'linear-gradient(45deg, #FF6B6B, #4ECDC4)',
    content: (
      <div className={styles.nextStepsContainer}>
        <motion.div
          className={styles.nextStepsContent}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <Text className={styles.nextStepsText}>
            Check out your all-time
            <br />
            favorite tracks!
          </Text>
          <Button
            className={styles.roastButton}
            variant="contained"
            onClick={() => {
              window.location.href = '/top/songs';
            }}
          >
            VIEW TOP SONGS 🎵
          </Button>
        </motion.div>
      </div>
    )
  }
];

export default function TrackRoast() {
  const [roastData, setRoastData] = useState<RoastResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [timeRange, setTimeRange] = useState<SpotifyTimeRange>("long_term");
  const [roastLevel, setRoastLevel] = useState<RoastLevel>("medium");
  const [showSlideshow, setShowSlideshow] = useState(false);

  useEffect(() => {
    const fetchRoast = async () => {
      try {
        setLoading(true);
        setError(null);
        const { data } = await api.getRoast(timeRange, roastLevel);
        setRoastData(data);
        setLoading(false);
      } catch (err) {
        setError("Failed to roast your music taste. Maybe it's already burned? 🔥");
        setLoading(false);
      }
    };

    fetchRoast();
  }, [timeRange, roastLevel]);

  const handleShare = () => {
    if (roastData?.roast.sharing_caption) {
      if (navigator.share) {
        navigator.share({
          title: 'My Spotify Roast',
          text: roastData.roast.sharing_caption,
          url: window.location.href,
        }).catch(console.error);
      } else {
        // Fallback sharing options
        // You can implement clipboard copy or other sharing methods here
        navigator.clipboard.writeText(roastData.roast.sharing_caption);
        // Optionally show a toast notification that text was copied
      }
    }
  };

  if (error) return <div className="text-red-500 p-4">{error}</div>;

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <Loader />
        <Text className={styles.loadingMessage}>
          Preparing your roast... This might burn a little 🔥
        </Text>
        <Text className={styles.loadingSubtext}>
          Our AI is sharpening its wit...
        </Text>
      </div>
    );
  }

  if (showSlideshow && roastData) {
    return (
      <StorySlideshow
        slides={createRoastSlides(roastData.roast)}
        onClose={() => setShowSlideshow(false)}
        onShare={handleShare}
      />
    );
  }

  return (
    <div className={styles.container}>
      <Header
        title="Taste Roaster"
        subtitle="AI-powered roasts of your music taste"
        right={
          <SpotifyTimeRangeSelector
            value={timeRange}
            onChange={setTimeRange}
          />
        }
        timeSelectorType="none"
      />
      
      <div className={styles.roastLevelContainer}>
        <RoastLevelSelector value={roastLevel} onChange={setRoastLevel} />
      </div>

      <div className={styles.startButton}>
        <Button 
          variant="contained"
          size="large"
          onClick={() => setShowSlideshow(true)}
          className={styles.viewButton}
        >
          Start Roast Experience
        </Button>
      </div>
    </div>
  );
}
