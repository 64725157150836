import { createAction, createReducer } from "@reduxjs/toolkit";
import { api } from "../../../apis/api";
import { presetIntervals, SpotifyTimeRange } from "../../../intervals";
import {
  changeUsername,
  checkLogged,
  deletePublicToken,
  generateNewPublicToken,
  setDarkMode,
} from "./thunk";
import { ReduxIntervalDetail, User } from "./types";
import { intervalDetailToRedux } from "./utils";

interface UserReducer {
  loaded: boolean;
  user: User | null;
  intervalDetail: ReduxIntervalDetail;
  publicToken: string | null;
  spotifyTimeRange: SpotifyTimeRange;
}

const initialState: UserReducer = {
  loaded: false,
  user: null,
  intervalDetail: intervalDetailToRedux(presetIntervals[0]),
  publicToken: null,
  spotifyTimeRange: 'medium_term',
};

export const logout = createAction("@user/logout");
export const setDataInterval =
  createAction<ReduxIntervalDetail>("@user/set-interval");
export const setPublicToken = createAction<string | null>(
  "@user/set-public-token",
);
export const setSpotifyTimeRange = createAction<SpotifyTimeRange>(
  "@user/set-spotify-time-range"
);
export const setUser = createAction<User>("@user/set-user");

export default createReducer(initialState, builder => {
  builder
    .addCase(logout, state => {
      state.user = null;
      state.publicToken = null;
      api.publicToken = null;
    })
    .addCase(setPublicToken, (state, { payload }) => {
      state.publicToken = payload;
      api.publicToken = payload;
    })
    .addCase(checkLogged.fulfilled, (state, { payload }) => {
      state.user = payload;
      state.loaded = true;
      if (state.user) {
        state.user.isGuest = !!api.publicToken;
        if (state.user.settings.spotifyTimeRange) {
          state.spotifyTimeRange = state.user.settings.spotifyTimeRange;
        }
      }
    })
    .addCase(setSpotifyTimeRange, (state, { payload }) => {
      state.spotifyTimeRange = payload;
      if (state.user) {
        state.user.settings.spotifyTimeRange = payload;
      }
    })
    .addCase(changeUsername.fulfilled, (state, { meta: { arg } }) => {
      if (state.user) {
        state.user.username = arg;
      }
    })
    .addCase(generateNewPublicToken.fulfilled, (state, { payload }) => {
      if (!state.user) {
        return;
      }
      state.user.publicToken = payload;
    })
    .addCase(deletePublicToken.fulfilled, (state, { payload }) => {
      if (!state.user) {
        return;
      }
      state.user.publicToken = null;
    })
    .addCase(setDataInterval, (state, { payload }) => {
      state.intervalDetail = payload;
    })
    .addCase(setDarkMode.pending, (state, { meta: { arg } }) => {
      if (!state.user) {
        return;
      }
      state.user.settings.darkMode = arg;
    })
    .addCase(setUser, (state, { payload }) => {
      state.user = payload;
    });
});
