import posthog from 'posthog-js'

// Initialize PostHog with your project API key and host URL
export const initPostHog = () => {
  posthog.init('phc_NaegtA8uWJ37hqG0XTfqtwNzUX4YXJHgHoicJGU7tCZ', {
    api_host: 'https://us.i.posthog.com',
    person_profiles: 'identified_only',
    // Enable autocapture for all environments
    autocapture: true,
    capture_pageview: true
  })
}

export const identify = (userId: string, properties?: Record<string, any>) => {
  posthog.identify(userId, properties)
}

export const capture = (eventName: string, properties?: Record<string, any>) => {
  posthog.capture(eventName, properties)
}

export default posthog
