import { useMemo } from "react";
import Text from "../../../../components/Text";
import { useMobile } from "../../../../services/hooks/hooks";
import { ColumnDescription, GridRowWrapper } from "../../../../components/Grid";
import s from "./index.module.css";
import { useTrackGrid } from "./TrackGrid";

export default function TrackHeader() {
  const [isMobile, isTablet] = useMobile();

  const trackGrid = useTrackGrid();

  const columns = useMemo<ColumnDescription[]>(
    () => [
      {
        ...trackGrid.cover,
        node: null,
      },
      {
        ...trackGrid.title,
        node: <Text>Title</Text>,
      },
      {
        ...trackGrid.album,
        node: !isTablet && <Text>Album</Text>,
      },
      {
        ...trackGrid.duration,
        node: !isMobile && <Text>Duration</Text>,
      },
    ],
    [isMobile, isTablet],
  );

  return <GridRowWrapper columns={columns} className={s.header} />;
}
