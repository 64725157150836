import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import s from './index.module.css';

export default function PaymentError() {
  const navigate = useNavigate();

  return (
    <div className={s.root}>
      <div className={s.content}>
        <h1>Payment Failed</h1>
        <p>Something went wrong with your payment.</p>
        <p>Please try again or contact support if the problem persists.</p>
        <Button 
          variant="contained" 
          color="primary"
          onClick={() => navigate('/all')}
          className={s.button}
        >
          Return to Stats
        </Button>
      </div>
    </div>
  );
}
